import { DCore } from "../config";
import qs from "qs";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export { makeActionCreator } from "./makeActionCreator";
export { createReducer } from "./createReducer";

export function splitArray(array, part) {
  var tmp = [];
  for (var i = 0; i < array.length; i += part) {
    tmp.push(array.slice(i, i + part));
  }
  return tmp;
}

export function checkUser(password) {
  const data = qs.stringify({
    username: localStorage.getItem("username") ?? "",
    password: password,
    grant_type: "password",
    client_id: "frontend-admin",
    client_secret: "e1f294db-5218-404b-bfcd-323f8defd759",
    scope: "openid",
  });

  return DCore.post(
    //"https://ier2-stag-keycloak.galaxygroup.biz/auth/realms/ier/protocol/openid-connect/token/",
    process.env.REACT_APP_KEYCLOACK_URL +"realms/ier/protocol/openid-connect/token/",
    data,
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer`,
    }
  );
}

export function download(
  path,
  name,
  type,
  url = process.env.REACT_APP_D_CORE_URL + "applications/file/"
) {
  if (path != undefined && path != null) {
    path = encodeURIComponent(
      path.substring(path.indexOf("/tmp"), path.length)
    );
    DCore.get(url + path).then((res) => {
      if (res.data.fileText != null) {
        var a = document.createElement("a");
        a.href = "data:image/" + res.data.type + ";base64," + res.data.fileText;
        a.download = name + "." + type;
        a.click();
      }
    });
  } else {
    DCore.get(url).then((res) => {
      if (res.data.fileText != null) {
        var a = document.createElement("a");
        a.href = "data:image/" + res.data.type + ";base64," + res.data.fileText;
        a.download = name + "." + type;
        a.click();
      }
    });
  }
}

export const exportExcel = (data, name = "data") => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(fileData, name + fileExtension);
};


