import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";
import { updateLoader } from "./../actions/updateLoader";
import { splitArray } from "../utility/index";

import {
  GETUSERS_ACTION,
  getUsersSuccessful,
  CREATEUSER_ACTION,
  getUsersAction,
  GETROLES_ACTION,
  UPDATEUSER_ACTION,
  DELETEUSER_ACTION,
} from "../actions";

function* getUsersSaga(payload) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      payload.data
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL + "users/api/v1/users"
    );
    let data = response.data.data;
    yield put(
      getUsersSuccessful({
        users: data,
        total: response.data.recordsTotal,
        page: response.data.start,
      })
    );
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* getRolesSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "users/api/v1/roles"
    );
    yield put(getUsersSuccessful({ roles: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* createUserSaga(payload) {
  try {
    console.log(payload);
    const response = yield call(
      DCore.post,
      process.env.REACT_APP_D_CORE_URL + "users/api/v1/users",
      payload.data
    );
    yield put(getUsersAction());
  } catch (e) {
    console.log(e);
  }
}

function* deleteUserSaga(payload) {
  try {
    const response = yield call(
      DCore.destroy,
      process.env.REACT_APP_D_CORE_URL + "users/api/v1/users/" + payload.data
    );
    yield put(getUsersAction());
  } catch (e) {
    console.log(e);
  }
}

function* updateUserSaga(payload) {
  try {
    console.log(payload);
    const response = yield call(
      DCore.put,
      process.env.REACT_APP_D_CORE_URL + "users/api/v1/users/edit",
      payload.data
    );
    yield put(getUsersAction());
  } catch (e) {
    console.log(e);
  }
}

export function* userSaga() {
  yield takeLatest(CREATEUSER_ACTION, createUserSaga);
  yield takeLatest(GETUSERS_ACTION, getUsersSaga);
  yield takeLatest(GETROLES_ACTION, getRolesSaga);
  yield takeLatest(UPDATEUSER_ACTION, updateUserSaga);
  yield takeLatest(DELETEUSER_ACTION, deleteUserSaga);
  yield takeLatest(GETROLES_ACTION, getRolesSaga);
}
