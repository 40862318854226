import {makeActionCreator} from '../utility'


export const GETEVENTS_ACTION = 'GETEVENTS_ACTION'
export const getEventsAction = makeActionCreator(GETEVENTS_ACTION, 'data')

export const CREATEEVENT_ACTION = 'CREATEEVENT_ACTION'
export const createEventAction = makeActionCreator(CREATEEVENT_ACTION, 'data')

export const DELETEEVENT_ACTION = 'DELETEEVENT_ACTION'
export const deleteEventAction = makeActionCreator(DELETEEVENT_ACTION, 'data')

export const UPDATEEVENT_ACTION = 'UPDATEEVENT_ACTION'
export const updateEventAction = makeActionCreator(UPDATEEVENT_ACTION, 'data')


export const GETEVENTS_SUCCESSFUL = 'GETEVENTS_SUCCESSFUL'
export const getEventsSuccessful = makeActionCreator(GETEVENTS_SUCCESSFUL, 'data')
