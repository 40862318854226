import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";
import { updateLoader } from "./../actions/updateLoader";

import {
  GETPAYMENT_ACTION,
  getpaymentAction,
  GETPAYMENT_BILL_ACTION,
  getBillsAction,
  ADDPAYMENT_BILL_ACTION,
  getpaymentSuccessful,
  CONFIRMPAYMENT_ACTION,
  GETPAYMENT_METHODS_ACTION,
  GETPAYMENT_STATUSES_ACTION,
} from "../actions";

function* paymentSaga(payload) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      payload.data
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL +
            "payments/paymentSearch?page=1&size=10&searchBy"
    );
    const pays = response.data.data;
    // const l = []
    // l.push(pays)
    yield put(
      getpaymentSuccessful({
        payments: pays,
        total: response.data.recordsTotal,
        page: response.data.start,
      })
    );
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* billsSaga(payload) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      payload.data
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL + "payments/bills/all"
    );
    const bills = response.data.data;
    yield put(
      getpaymentSuccessful({
        bills: bills,
        total: response.data.recordsTotal,
        page: response.data.start,
      })
    );
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* paymentMethodsSaga(paylaod) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "payments/paymentMethods"
    );
    yield put(getpaymentSuccessful({ methods: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* paymentStatusesSaga(paylaod) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "payments/statuses"
    );
    yield put(getpaymentSuccessful({ statuses: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* confirmPaymentSaga(payload) {
  try {
    const response = yield call(
      DCore.put,
      payload.data.url
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL + "payments/confirmPayment",
      payload.data.data
    );
    yield put(getpaymentAction());
  } catch (e) {
    console.log(e);
  }
}

function* addBillSaga(payload) {
  try {
    const response = yield call(
      DCore.put,
      payload.data.url
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL + "payments/confirmPayment",
      payload.data.data
    );
    yield put(getBillsAction());
  } catch (e) {
    console.log(e);
  }
}

export function* paySaga() {
  yield takeLatest(GETPAYMENT_ACTION, paymentSaga);
  yield takeLatest(GETPAYMENT_BILL_ACTION, billsSaga);
  yield takeLatest(CONFIRMPAYMENT_ACTION, confirmPaymentSaga);
  yield takeLatest(GETPAYMENT_METHODS_ACTION, paymentMethodsSaga);
  yield takeLatest(GETPAYMENT_STATUSES_ACTION, paymentStatusesSaga);
  yield takeLatest(ADDPAYMENT_BILL_ACTION, addBillSaga);
}
