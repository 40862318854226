import {createReducer} from '../utility'




export const getPayments = createReducer({},{
    "GETPAYMENT_SUCCESSFUL"(state, {data}){
        if(true){
            return {
                ...state,
                ...data
            }
        }else{
            return{
                ...state
            }
        }
    } 
})