import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";

import { SIGNIN_ACTION, OTP_ACTION, signinSuccessful } from "../actions";
import { updateLoader } from "../actions/updateLoader";

function* signinSaga(payload) {
  try {
    yield put(updateLoader({ login_form: null }));
    yield put(updateLoader({ loginLoading: true }));
    const response = yield call(
      DCore.post,
      process.env.REACT_APP_D_CORE_URL +"deviceManager/client/requestDeviceCreation",
      payload.data,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer`,
      }
    );

    yield put(signinSuccessful(response));
    yield put(updateLoader({ loginLoading: false }));
    localStorage.setItem("brand", payload.data.brand);
    localStorage.setItem("model", payload.data.model);
    localStorage.setItem("msisdn", payload.data.msisdn);
    localStorage.setItem("serialNumber", payload.data.serialNumber);
    window.location.href = "/verify";
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ loginLoading: false }));
    yield put(updateLoader({ login_form: "Invalid credentials!!" }));
  }
}

function* otpSaga(payload) {
  try {
    yield put(updateLoader({ login_form: null }));
    yield put(updateLoader({ loginLoading: true }));
    const response = yield call(
      DCore.put,
      process.env.REACT_APP_D_CORE_URL +"deviceManager/client/confirmDeviceCreation",
      payload.data,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer`,
      }
    );

    yield put(signinSuccessful(response));
    yield put(updateLoader({ loginLoading: false }));
    localStorage.setItem("isLoggedin", true);
    localStorage.setItem("brand", payload.data.brand);
    localStorage.setItem("model", payload.data.model);
    localStorage.setItem("msisdn", payload.data.msisdn);
    localStorage.setItem("serialNumber", payload.data.serialNumber);
    localStorage.setItem("token", response.data.authId);
    window.location.href = "/";
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ loginLoading: false }));
    yield put(updateLoader({ login_form: "Invalid credentials!!" }));
  }
}


export function* authSaga() {
  yield takeLatest(SIGNIN_ACTION, signinSaga);
  yield takeLatest(OTP_ACTION, otpSaga);
}
