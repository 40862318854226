import "./App.scss";
import Home from "./pages/home/home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {useState} from "react";


function App() {

  return (
    <Router>
    <div className="m_container">
      <div className="main">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </div>
  </Router>
  )
}

export default App;
