import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";
import { updateLoader } from "../actions/updateLoader";
import {
  GETMEMEBERPROFILE_ACTION,
  getMemberProfileSuccessful,
} from "../actions";

function* getProfileMemberSaga(payload) {
  const url =
    payload.data.type == "firm"
      ? process.env.REACT_APP_D_CORE_URL + "members/firm/" + payload.data.id
      : process.env.REACT_APP_D_CORE_URL +
        "members/individuals/" +
        payload.data.id;
  yield put(getMemberProfileSuccessful({}));
  try {
    yield put(updateLoader({ profileMemberLoading: true }));
    const response = yield call(DCore.get, url);
    const member = response.data;
    yield put(getMemberProfileSuccessful(member));
    yield put(updateLoader({ profileMemberLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ profileMemberLoading: false }));
  }
}

export function* memberProfileSaga() {
  yield takeLatest(GETMEMEBERPROFILE_ACTION, getProfileMemberSaga);
}
