import {makeActionCreator} from '../utility'


export const GETAPPLICATION_ACTION = 'GETAPPLICATION_ACTION'
export const getApplicationAction = makeActionCreator(GETAPPLICATION_ACTION, 'data')

export const GETAPPLICATION_STATUS_ACTION = 'GETAPPLICATION_STATUS_ACTION'
export const getApplicationStatusAction = makeActionCreator(GETAPPLICATION_STATUS_ACTION, 'data')


export const UPDATEAPPLICATION_STATUS_ACTION = 'UPDATEAPPLICATION_STATUS_ACTION'
export const updateApplicationStatusAction = makeActionCreator(UPDATEAPPLICATION_STATUS_ACTION, 'data')


export const INVITE_FOR_INTERVIEW_ACTION = 'INVITE_FOR_INTERVIEW_ACTION'
export const inviteForInterviewAction = makeActionCreator(INVITE_FOR_INTERVIEW_ACTION, 'data')


export const GET_SINGLE_APPLICATION_ACTION = 'GET_SINGLE_APPLICATION_ACTION'
export const getSingleApplicationAction = makeActionCreator(GET_SINGLE_APPLICATION_ACTION, 'data')

export const GETAPPLICATION_MEMBERSHIP_ACTION = 'GETAPPLICATION_MEMBERSHIP_ACTION'
export const getApplicationMembershipAction = makeActionCreator(GETAPPLICATION_MEMBERSHIP_ACTION, 'data')


export const GETAPPLICATION_SUCCESSFUL = 'GETAPPLICATION_SUCCESSFUL'
export const getApplicationSuccessful = makeActionCreator(GETAPPLICATION_SUCCESSFUL, 'data')
