import {makeActionCreator} from '../utility'


export const GETMEMEBER_ACTION = 'GETMEMEBER_ACTION'
export const getMemberAction = makeActionCreator(GETMEMEBER_ACTION, 'data')


export const GETMEMEBER_DISCIPLINE_ACTION = 'GETMEMEBER_DISCIPLINE_ACTION'
export const getMemberDisciplineAction = makeActionCreator(GETMEMEBER_DISCIPLINE_ACTION, 'data')

export const GETCOUNTRIES_ACTION = 'GETCOUNTRIES_ACTION'
export const getCountryAction = makeActionCreator(GETCOUNTRIES_ACTION, 'data')


export const GETPROVINCE_ACTION = 'GETPROVINCE_ACTION'
export const getProvinceAction = makeActionCreator(GETPROVINCE_ACTION, 'data')

export const GETDISTRICT_ACTION = 'GETDISTRICT_ACTION'
export const getDistrictAction = makeActionCreator(GETDISTRICT_ACTION, 'data')

export const GETSECTOR_ACTION = 'GETSECTOR_ACTION'
export const getSectorAction = makeActionCreator(GETSECTOR_ACTION, 'data')

export const GETCELL_ACTION = 'GETMEMEBER_DISCIPLINE_ACTION'
export const getCellAction = makeActionCreator(GETCELL_ACTION, 'data')

export const GETVILLAGE_ACTION = 'GETMEMEBER_DISCIPLINE_ACTION'
export const getVillageAction = makeActionCreator(GETVILLAGE_ACTION, 'data')


export const GET_AWARD_TYPES_ACTION = 'GET_AWARD_TYPES_ACTION'
export const getAwardTypesAction = makeActionCreator(GET_AWARD_TYPES_ACTION, 'data')


export const GETMEMEBER_STATUSES_ACTION = 'GETMEMEBER_STATUSES_ACTION'
export const getMemberStatusesAction = makeActionCreator(GETMEMEBER_STATUSES_ACTION, 'data')


export const GETMEMEBER_SUCCESSFUL = 'GETMEMEBER_SUCCESSFUL'
export const getMemberSuccessful = makeActionCreator(GETMEMEBER_SUCCESSFUL, 'data')

export const UPDATE_MEMBERSHIP = 'UPDATE_MEMBERSHIP'
export const updateMembeshipStatusAction = makeActionCreator(UPDATE_MEMBERSHIP, 'data')
