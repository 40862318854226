import React from "react";
import "./topbar.scss";
import {useState} from "react";
import { PowerSettingsNew } from "@material-ui/icons";
import OutsideAlerter from "../utils/outside_click"
import {useLocation} from "react-router-dom";
import { Link } from 'react-scroll'

export default function Topbar({showHamburger, onShow}) {
  const icons = {
    logoImage : require('../../assets/images/logo.png').default,
    hamImage : require('../../assets/images/ham.svg').default,
  }
  const f_name = localStorage.getItem("first_name") 
  const l_name = localStorage.getItem("last_name") 
  const [showDrawer, setShowDrawer] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const location = useLocation()
  console.log(location)
  return (
    <div className="topbar">
      <div className="topbar_wrapper">
        <div className="logo">
          <img src={icons.logoImage}/>
        </div>
        <div className = "_menu_items">
          <ul className="menu_li">
            <Link to="home" exact className="nav_link" activeClass = "active" onClick={()=> window.location.hash = '#home'}>
                <li className={"list_item" + ((location.hash == "#home") || (location.hash == "" && location.pathname == "/") ?  " active" : "")}> Home</li>
            </Link>
            <Link to="products" exact className="nav_link"   onClick={()=> window.location.hash = '#products'}>
                <li className={"list_item" + (location.hash == "#products" ? " active" : "")}> Our Solutions</li>
            </Link>
            <Link to="partners" exact className="nav_link"   onClick={()=> window.location.hash = '#partners'}>
                <li className={"list_item" + (location.hash == "#partners" ? " active" : "")}> Our partners</li>
            </Link>
            <Link to="team" exact className="nav_link"   onClick={()=> window.location.hash = '#team'}>
                <li className={"list_item" + (location.hash == "#team" ? " active" : "")}> Team</li>
            </Link>
            <Link to="get-in-touch" exact className="nav_link"   onClick={()=> window.location.hash = '#get-in-touch'}>
                <li className={"list_item" + (location.hash == "#get-in-touch" ? " active" : "")}> Get in touch</li>
            </Link>
            <Link to="visit-us" exact className="nav_link"   onClick={()=> window.location.hash = '#visit-us'}>
                <li className={"list_item" + (location.hash == "#visit-us" ? " active" : "")}> Visit Us</li>
            </Link>
            {/* <Link to="/signin">
              <button className="_btn" style={{marginTop : "-10px"}}>Sign In</button>
            </Link> */}
          </ul>
        </div>
        <div className="ham">
          <img src={icons.hamImage} onClick={()=> setShowDrawer(true)}/>
        </div>
      </div>
      <div className={showDrawer ? "mb_nav" : "mb_nav hide"}>
          <div className="top_l_phone">
            <img src={icons.logoImage} alt="logo" height={30}/>
            <span className="x" onClick = {() => setShowDrawer(false)}>X</span>
          </div>  
          <div className="nav_wrapper_phone">
            <ul className="nav_list_phone">
            <Link to="home" exact className="nav_link" activeClass = "active" onClick={()=> window.location.hash = '#home'}>
                <li className={"list_item" + ((location.hash == "#home") || (location.hash == "" && location.pathname == "/") ?  " active" : "")}  onClick = {() => setShowDrawer(false)}> Home</li>
            </Link>
            <Link to="products" exact className="nav_link"   onClick={()=> window.location.hash = '#products'}>
                <li className={"list_item" + (location.hash == "#products" ? " active" : "")}  onClick = {() => setShowDrawer(false)}> Our Solutions</li>
            </Link>
            <Link to="partners" exact className="nav_link"   onClick={()=> window.location.hash = '#partners'}>
                <li className={"list_item" + (location.hash == "#partners" ? " active" : "")}  onClick = {() => setShowDrawer(false)}> Our partners</li>
            </Link>
            <Link to="team" exact className="nav_link"   onClick={()=> window.location.hash = '#team'}>
                <li className={"list_item" + (location.hash == "#team" ? " active" : "")}  onClick = {() => setShowDrawer(false)}> Team</li>
            </Link>
            <Link to="get-in-touch" exact className="nav_link"   onClick={()=> window.location.hash = '#get-in-touch'}>
                <li className={"list_item" + (location.hash == "#get-in-touch" ? " active" : "")}  onClick = {() => setShowDrawer(false)}> Get in touch</li>
            </Link>
            <Link to="visit-us" exact className="nav_link"   onClick={()=> window.location.hash = '#visit-us'}>
                <li className={"list_item" + (location.hash == "#visit-us" ? " active" : "")}  onClick = {() => setShowDrawer(false)}> Visit Us</li>
            </Link>
            </ul>
            {/* <div className="nav_action">
              <button className="_btn _navbtn" onClick={()=> setShowModal(true)}>Register a Vehicle</button>
            </div> */}
          </div>
      </div>
    </div>
  );
}
