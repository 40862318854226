import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import RootReducer from '../reducers';
import * as sagas from '../sagas';


const sagaMiddleware = createSagaMiddleware();

const store = createStore(RootReducer, applyMiddleware(sagaMiddleware));
export default store

for (let saga in sagas) {
    sagaMiddleware.run(sagas[saga]);
}