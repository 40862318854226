import {makeActionCreator} from '../utility'


export const SIGNIN_ACTION = 'SIGNIN_ACTION'
export const signinAction = makeActionCreator(SIGNIN_ACTION, 'data')


export const OTP_ACTION = 'OTP_ACTION'
export const getOtpAction = makeActionCreator(OTP_ACTION, 'data')


export const SIGNIN_SUCCESSFUL = 'SIGNIN_SUCCESSFUL'
export const signinSuccessful = makeActionCreator(SIGNIN_SUCCESSFUL, 'data')
