import {combineReducers} from 'redux';
import {loader} from './loader'
import {signIn} from './authReducer'
import {getApplication} from './applicationReducer'
import {getPayments} from './paymentReducer'
import {getMembers} from './memberReducer'
import {getMemberProfile} from './memberProfileReducer'
import {getUsers} from './userReducer'
import {getEvents} from './eventReducer'




const rootReducer = combineReducers({
    loader,
    signIn,
    getApplication,
    getPayments,
    getMembers,
    getMemberProfile,
    getUsers,
    getEvents
})

export default rootReducer