import "./home.scss";
import React, { useState, useEffect, useMemo, useRef } from "react";
import CalendarIcon from "../../components/calendar_icon";
import Topbar from "../../components/topbar/Topbar";
import { useDispatch, useSelector } from "react-redux";
import { DCore } from "../../config";
import { splitArray } from "../../utility/index";
import { getApplicationSuccessful } from "../../actions/application";
import { getMemberSuccessful } from "../../actions/member";
import { getpaymentSuccessful } from "../../actions/payment";
import {useLocation} from "react-router-dom";
import ListTile from "../../components/card/list_tile";
import Popup from "../../components/popup/popup";
import Card from "../../components/card";
import InputForm from "../../components/input/input_form";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

export default function Home() {
  const dispatch = useDispatch();
  useSelector(function (state) {
    console.log(state);
  });


  useEffect(() => {

  }, []);
  const { isLoaded } = useLoadScript({ googleMapsApiKey: 'AIzaSyAt70QkOccELWgYLbrMsJzQTqxijfdKzj8'});
  const [showModal, setShowModal] = useState(false);
  const [showStep, setShowStep] = useState(1);
  const stats = useSelector((state) => state.getApplication.stats) ?? [];
  const m_stats = useSelector((state) => state.getMembers.stats) ?? [];
  const p_stats = useSelector((state) => state.getPayments.stats) ?? [];
  const location = useLocation();

  const data = []; 

  return (
    <div>
    <div className="home_wrapper">
      <Topbar/>
      <div className="home_container">
        <section id="home">
          <div className="s_h_container cont_w850">
            <div className="text_content f_1">
              <h1>Welcome,<br/>Flex with us</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et at fringilla<br/>
              id maecenas in scelerisque in. Pharetra, erat amet, vestibulum<br/> ullamcorper. </p>
            </div>
            <div className="s_h_bg">
              <img src={require('../../assets/images/home_bg.svg').default}/>
            </div>
          </div>
        </section>
        <section id="products">
          <div  className="cont_w850">
            <h3>Our Products</h3>
            <div className="s_p_container">
              <div className="_item_partner">
                <img src = {require('../../assets/images/flexgo.svg').default}/>
                <h4>FlexGo</h4>
                <p>All things are designed. A few things are designed well. We try to elevate your ideas with great UI/UX design, suited to your taste, 
                  addressing the right pain points.</p>
              </div>
              <div className="_item_partner">
              <img src = {require('../../assets/images/konvey.svg').default}/>
                <h4>Konvey</h4>
                <p>All things are designed. A few things are designed well. We try to elevate your ideas with great UI/UX design, 
                  suited to your taste, addressing the right pain points.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="partners">
          <div className="cont_w850">
          <h3>Our partners</h3>
          <div className="s_pa_container">
            <img src={require('../../assets/images/image_1.svg').default}/>
            <img src={require('../../assets/images/image_2.svg').default}/>
            <img src={require('../../assets/images/image_3.svg').default}/>
            <img src={require('../../assets/images/image_4.svg').default}/>
            <img src={require('../../assets/images/image_5.svg').default}/>
          </div>
          </div>
        </section>
        <section id="team">
          <div className="cont_w850">
            <h3>Our Team</h3>
            <div className="s_t_container">
              <div className="person_card">
                <div className="avtr_"></div>
                <h5>JOHN DOE</h5>
                <span>CFO</span>
              </div>
              <div className="person_card _bg">
                <div className="avtr_"></div>
                <h5>KARAMAGE LIVINGSTONE</h5>
                <span>CEO</span>
              </div>
              <div className="person_card">
                <div className="avtr_"></div>
                <h5>KAYITARE PRINCE</h5>
                <span>CTIO</span>
              </div>
            </div>
          </div>
        </section>
        <section id="get-in-touch" style={{backgroundColor : "white"}}>
          <div className="cont_w850">
            <div className=" _bg_pic mg_b">
            <div className="_card">
              <h3>Contact Us</h3>
              <p>Let's discuss what projects <br/>you have in mind</p>
              <button>Let’s Have a Coffee</button>
            </div>
            </div>
            <div className="input_">
              <input type="text" placeholder="Full name"/>
              <div className="row_input">
                <input type="text" placeholder="Email"/>
                &nbsp;&nbsp;&nbsp;
                <input type="text" placeholder="Phone"/>
              </div>
              <textarea placeholder="Message"/>
            </div>
            <button className="black_btn">Send</button>
          </div>
        </section>
        <section id="visit-us">
          <div className="cont_w850 _sep_">
            <div className="sid_1">
              <h3>Location</h3>
              <div className="div_map">
              {isLoaded && <Map/>}
                {!isLoaded && <h4>loading</h4>}
              </div>
            </div>
            <div className="sid_2">
              <h3>Get in touch</h3>
              <div className="div_contact_info">
                <div className="social_icon">
                  <img src={require("../../assets/images/phone.svg").default}/>
                  <span>0788313131</span>
                </div>
                <div className="social_icon">
                  <img src={require("../../assets/images/mail.svg").default}/>
                  <span>contact@flexplatforms.com</span>
                </div>
                <div className="social_icon">
                  <img src={require("../../assets/images/fb.svg").default}/>
                  <span>FlexFb</span>
                </div>
                <div className="social_icon">
                  <img src={require("../../assets/images/ig.svg").default}/>
                  <span>FlexIG</span>
                </div>
                <div className="social_icon">
                  <img src={require("../../assets/images/twitter.svg").default}/>
                  <span>FlexTwitter</span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="bottom">
          <div className="cont_w850">
            <img src= {require("../../assets/images/logo_white.svg").default} />
          </div>
        </section>
      </div>
    </div>
    </div>
  );
}

function Map() {
  const center = useMemo(() => ({ lat: -1.9526036560418754, lng: 30.0927129843511 }), []);

  return (
    <GoogleMap zoom={16} center={center} mapContainerClassName="div_map">
      <Marker position={center} />
    </GoogleMap>
  );
}
