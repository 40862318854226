import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";
import { updateLoader } from "./../actions/updateLoader";

import {
  GETAPPLICATION_ACTION,
  getApplicationSuccessful,
  getApplicationAction,
  UPDATEAPPLICATION_STATUS_ACTION,
  INVITE_FOR_INTERVIEW_ACTION,
  GET_SINGLE_APPLICATION_ACTION,
  GETAPPLICATION_STATUS_ACTION,
  GETAPPLICATION_MEMBERSHIP_ACTION,
} from "../actions";

function* getApplicationSaga(payload) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      payload.data
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL +
            "applications?page=1&size=10&searchBy"
    );
    const apps = response.data.data;
    yield put(
      getApplicationSuccessful({
        applications: apps,
        total: response.data.recordsTotal,
        page: response.data.start,
      })
    );
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* getSingleApplicationSaga(payload) {
  console.log(payload.data);
  yield put(getApplicationSuccessful({ selected: {} }));
  try {
    const response = yield call(
      DCore.get,
      payload.data.type == "individual"
        ? process.env.REACT_APP_D_CORE_URL +
            "applications/" +
            payload.data.id +
            "/individuals"
        : process.env.REACT_APP_D_CORE_URL +
            "applications/" +
            payload.data.id +
            "/firms"
    );
    const app = response.data;
    yield put(getApplicationSuccessful({ selected: app }));
    // yield put(updateLoader({tableLoading : false}));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* getApplicationStatusSaga(paylaod) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "applications/status"
    );
    console.log(response.data);
    yield put(getApplicationSuccessful({ status: response.data }));
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* updateApplicationStatusSaga(payload) {
  try {
    const response = yield call(
      DCore.post,
      payload.data.url ??
        process.env.REACT_APP_D_CORE_URL + "applications/update-status",
      payload.data
    );
    yield put(getApplicationAction({url : payload.data.uri}));
  } catch (e) {
    console.log(e);
  }
}

function* inviteForInterviewSaga(payload) {
  try {
    const response = yield call(
      DCore.post,
      process.env.REACT_APP_D_CORE_URL + "applications/update-status",
      payload.data
    );
    yield put(getApplicationAction({url : payload.data.uri}));
  } catch (e) {
    console.log(e);
  }
}

function* getApplicationMembershipSaga(paylaod) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "membershipclasses"
    );
    console.log(response.data);
    yield put(getApplicationSuccessful({ memberships: response.data }));
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

export function* applicationSaga() {
  yield takeLatest(GETAPPLICATION_ACTION, getApplicationSaga);
  yield takeLatest(GET_SINGLE_APPLICATION_ACTION, getSingleApplicationSaga);
  yield takeLatest(GETAPPLICATION_STATUS_ACTION, getApplicationStatusSaga);
  yield takeLatest(
    UPDATEAPPLICATION_STATUS_ACTION,
    updateApplicationStatusSaga
  );
  yield takeLatest(INVITE_FOR_INTERVIEW_ACTION, inviteForInterviewSaga);
  yield takeLatest(
    GETAPPLICATION_MEMBERSHIP_ACTION,
    getApplicationMembershipSaga
  );
}
