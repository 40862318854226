import {makeActionCreator} from '../utility'


export const GETUSERS_ACTION = 'GETUSERS_ACTION'
export const getUsersAction = makeActionCreator(GETUSERS_ACTION, 'data')

export const CREATEUSER_ACTION = 'CREATEUSER_ACTION'
export const createUsersAction = makeActionCreator(CREATEUSER_ACTION, 'data')

export const DELETEUSER_ACTION = 'DELETEUSER_ACTION'
export const deleteUserAction = makeActionCreator(DELETEUSER_ACTION, 'data')

export const UPDATEUSER_ACTION = 'UPDATEUSER_ACTION'
export const updateUserction = makeActionCreator(UPDATEUSER_ACTION, 'data')

export const GETROLES_ACTION = 'GETROLES_ACTION'
export const getRolesAction = makeActionCreator(GETROLES_ACTION, 'data')


export const GETUSERS_SUCCESSFUL = 'GETUSERS_SUCCESSFUL'
export const getUsersSuccessful = makeActionCreator(GETUSERS_SUCCESSFUL, 'data')
