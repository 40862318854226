import {makeActionCreator} from '../utility'


export const GETPAYMENT_ACTION = 'GETPAYMENT_ACTION'
export const getpaymentAction = makeActionCreator(GETPAYMENT_ACTION, 'data')


export const GETPAYMENT_BILL_ACTION = 'GETPAYMENT_BILL_ACTION'
export const getBillsAction = makeActionCreator(GETPAYMENT_BILL_ACTION, 'data')


export const ADDPAYMENT_BILL_ACTION = 'ADDPAYMENT_BILL_ACTION'
export const addBillAction = makeActionCreator(ADDPAYMENT_BILL_ACTION, 'data')


export const CONFIRMPAYMENT_ACTION = 'CONFIRMPAYMENT_ACTION'
export const confirmPaymentAction = makeActionCreator(CONFIRMPAYMENT_ACTION, 'data')


export const GETPAYMENT_METHODS_ACTION = 'GETPAYMENT_METHODS_ACTION'
export const getPaymentMethodsAction = makeActionCreator(GETPAYMENT_METHODS_ACTION, 'data')


export const GETPAYMENT_STATUSES_ACTION = 'GETPAYMENT_STATUSES_ACTION'
export const getPaymentStatusesAction = makeActionCreator(GETPAYMENT_STATUSES_ACTION, 'data')


export const GETPAYMENT_SUCCESSFUL = 'GETPAYMENT_SUCCESSFUL'
export const getpaymentSuccessful = makeActionCreator(GETPAYMENT_SUCCESSFUL, 'data')


