import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";
import { updateLoader } from "../actions/updateLoader";

import {
  GETEVENTS_ACTION,
  getEventsSuccessful,
  CREATEEVENT_ACTION,
  getEventsAction,
  UPDATEEVENT_ACTION,
  DELETEEVENT_ACTION,
} from "../actions";

function* getEventsSaga(payload) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "events"
    );
    let data = response.data;
    yield put(
      getEventsSuccessful({
        events: data,
        total: response.data.recordsTotal,
        page: response.data.start,
      })
    );
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* createEventSaga(payload) {
  try {
    console.log(payload);
    const response = yield call(
      DCore.post,
      process.env.REACT_APP_D_CORE_URL + "events",
      payload.data
    );
    yield put(getEventsAction());
  } catch (e) {
    console.log(e);
  }
}

function* updateEventSaga(payload) {
  try {
    const response = yield call(
      DCore.put,
      process.env.REACT_APP_D_CORE_URL + "events/"+payload.data.id,
      payload.data
    );
    yield put(getEventsAction());
  } catch (e) {
    console.log(e);
  }
}

function* deleteEventSaga(payload) {
  try {
    const response = yield call(
      DCore.delete,
      process.env.REACT_APP_D_CORE_URL + "api/user/user",
      payload.data
    );
    yield put(getEventsAction());
  } catch (e) {
    console.log(e);
  }
}



export function* eventSaga() {
  yield takeLatest(CREATEEVENT_ACTION, createEventSaga);
  yield takeLatest(GETEVENTS_ACTION, getEventsSaga);
  yield takeLatest(UPDATEEVENT_ACTION, updateEventSaga);
  yield takeLatest(DELETEEVENT_ACTION, deleteEventSaga);
}
