import { call, put, takeLatest } from "redux-saga/effects";
import { DCore } from "../config";
import { updateLoader } from "./../actions/updateLoader";
import {
  GETMEMEBER_ACTION,
  getMemberSuccessful,
  GETMEMEBER_DISCIPLINE_ACTION,
  GET_AWARD_TYPES_ACTION,
  UPDATE_MEMBERSHIP,
  getMemberAction,
  GETMEMEBER_STATUSES_ACTION,
  GETPROVINCE_ACTION,
  GETDISTRICT_ACTION,
  GETSECTOR_ACTION,
  GETVILLAGE_ACTION,
  GETCELL_ACTION,
  GETCOUNTRIES_ACTION,
} from "../actions";
import { Switch, Route, Link } from "react-router-dom";

function* getMemberSaga(payload) {
  try {
    yield put(updateLoader({ tableLoading: true }));
    const response = yield call(
      DCore.get,
      payload.data
        ? payload.data.url
        : process.env.REACT_APP_D_CORE_URL +
            "members/memberships/filter?page=1&size=10&searchBy"
    );

    const members = response.data.data;
    yield put(
      getMemberSuccessful({
        data: members,
        total: response.data.recordsTotal,
        page: response.data.start,
      })
    );
    yield put(updateLoader({ tableLoading: false }));
  } catch (e) {
    console.log(e);
    yield put(updateLoader({ tableLoading: false }));
  }
}

function* getDisciplineSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "disciplines"
    );
    yield put(getMemberSuccessful({ disciplines: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getProvincesSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "locations/types/province"
    );
    yield put(getMemberSuccessful({ provinces: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getDistrictsSaga(payload) {
  console.log(payload);
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "locations/parent/" + payload.data.id
    );
    yield put(getMemberSuccessful({ districts: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getCellsSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "locations/parent/" + payload.data.id
    );
    yield put(getMemberSuccessful({ cells: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getSectorsSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "locations/parent/" + payload.data.id
    );
    yield put(getMemberSuccessful({ sectors: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getVillagesSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "locations/parent/" + payload.data.id
    );
    yield put(getMemberSuccessful({ villages: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getCountriesSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "members/memberships/countries"
    );
    yield put(getMemberSuccessful({ countries: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* getAwardTypesSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "awards/types"
    );
    yield put(getMemberSuccessful({ awards: response.data }));
  } catch (e) {
    console.log(e);
  }
}

function* updateMembeshipStatusSaga(payload) {
  try {
    const response = yield call(
      DCore.post,
      process.env.REACT_APP_D_CORE_URL + "members/memberships/approve",
      payload.data
    );
    yield put(getMemberAction({url : payload.data.uri}));
  } catch (e) {
    console.log(e);
  }
}

function* getStatusesSaga(payload) {
  try {
    const response = yield call(
      DCore.get,
      process.env.REACT_APP_D_CORE_URL + "members/memberships/status"
    );
    const statuses = response.data
    yield put(getMemberSuccessful({ statuses }));
  } catch (e) {
    console.log(e);
  }
}

export function* memberSaga() {
  yield takeLatest(GETMEMEBER_ACTION, getMemberSaga);
  yield takeLatest(GETMEMEBER_DISCIPLINE_ACTION, getDisciplineSaga);
  yield takeLatest(GET_AWARD_TYPES_ACTION, getAwardTypesSaga);
  yield takeLatest(UPDATE_MEMBERSHIP, updateMembeshipStatusSaga);
  yield takeLatest(GETMEMEBER_STATUSES_ACTION, getStatusesSaga);
  yield takeLatest(GETCOUNTRIES_ACTION, getCountriesSaga);
  yield takeLatest(GETPROVINCE_ACTION, getProvincesSaga);
  yield takeLatest(GETDISTRICT_ACTION, getDistrictsSaga);
  yield takeLatest(GETCELL_ACTION, getCellsSaga);
  yield takeLatest(GETVILLAGE_ACTION, getVillagesSaga);
  yield takeLatest(GETSECTOR_ACTION, getSectorsSaga);
}
