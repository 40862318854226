import {createReducer} from '../utility'




export const getApplication = createReducer({},{
    "GETAPPLICATION_SUCCESSFUL"(state, {data}){
        console.log(data)
        if(true){
            return {
                ...state,
                ...data
            }
        }else{
            return{
                ...state
            }
        }
    } 
})