import {createReducer} from '../utility'




export const getEvents = createReducer({},{
    "GETEVENTS_SUCCESSFUL"(state, {data}){
        if(data){
            return {...state, ...data
            }
        }else{
            return{
                ...state
            }
        }
    } 
})

