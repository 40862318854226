import {createReducer} from '../utility'




export const signIn = createReducer({},{
    "SIGNIN_SUCCESSFUL"(state, {data}){
        if(true){
            return {
                ...state,
                loggedIn: data,
            }
        }else{
            return{
                ...state
            }
        }
    } 
})